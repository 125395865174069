import * as moment from "moment"
import { store } from "@/store"
import User from "@shared/User"

class PriceIsRightService {
  static async submit(user, value) {
    const mission = store.getters.getCurrentMission
    const time = moment(new Date())

    let obj = {}
    let priceIsRightResult = false

    if (value <= parseFloat(mission.answer)) priceIsRightResult = true

    obj.thisSubmit = true
    obj.answer = [value]
    obj.behavior = mission.behavior
    obj.correct = value
    obj.mission = mission.name
    obj.missionID = mission.id
    obj.points = parseInt(mission.points) || 0
    obj.result = true
    obj.priceIsRightResult = priceIsRightResult
    obj.show = true
    obj.teamID = user.teamID
    obj.time = time.unix()
    obj.userID = user.id

    let plays = store.getters.missionPlaysArray.filter(
      play => play.priceIsRightResult
    )
    let rank = 0

    obj.score = 0

    if (priceIsRightResult) {
      // when guessed correctly
      plays.push(obj)
      plays = plays.sort((a, b) => b.correct - a.correct)

      for (var i in plays) {
        if (plays[i].id) {
          const play = plays[i]
          play.id = plays[i].id
          var counter = parseInt(i) + 1
          play.score = Math.round(mission.points / counter)
          await store.dispatch("addPlay", play)
        }
        if (plays[i].thisSubmit) {
          delete obj.id
          rank = parseInt(i) + 1
          obj.thisSubmit = false
          var counter = parseInt(i) + 1
          obj.score = Math.round(mission.points / counter)
          await store.dispatch("addPlay", obj)
        }
      }
    } else {
      // got it over
      obj.thisSubmit = false
      obj.score = 0
      await store.dispatch("addPlay", obj)
    }

    const users = store.getters.onlineUsersArray.filter(User.isScribe)

    if (
      !(
        plays.length > 0 &&
        store.getters.missionPlaysArray.length >= users.length
      )
    )
      return

    const teams = store.getters.chats

    const promises = []

    for (var i in plays) {
      const teamScore = teams[plays[i].teamID].totalScore

      obj = {}
      rank = parseInt(i) + 1
      obj.score = mission.points / rank
      obj.teamScore = teamScore + obj.score
      obj.teamID = plays[i].teamID

      promises.push(store.dispatch("setTeamScore", obj))
    }

    await Promise.all(promises)
  }
}

export default PriceIsRightService
