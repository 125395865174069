













import Vue from "vue"
import _ from "lodash"
import shuffle from "knuth-shuffle-seeded"
import Mode from "@shared/enums/Mode"
import TextInput from "@/components/GroupTeams/Common/Games/TextInput.vue"
import MissionContainer from "@/components/GroupTeams/Common/Games/MissionContainer.vue"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"

import PriceIsRightService from "@/services/price-is-right.service"
import Team from "@shared/Team"
import User from "@shared/User"
import useBuzzIn from "@/use/useBuzzIn"

export default Vue.extend({
  name: "MobilePriceIsRight",
  components: {
    ResizableText,
    MissionContainer,
    TextInput
  },
  props: {
    mission: Object
  },
  setup() {
    const { seed } = useBuzzIn()
    return { seed }
  },
  data() {
    return {
      Mode,
      price: ""
    }
  },
  computed: {
    mode() {
      return this.isUserTurn ? Mode.Play : Mode.Explain
    },
    gameStatus() {
      return this.$store.getters.gameStatus
    },
    missionStarted() {
      return this.gameStatus?.missionStarted
    },
    teams() {
      return Team.normalize(this.$store.getters.chats)
    },
    scribes() {
      return this.$store.getters.onlineUsersArray.filter(User.isScribe)
    },
    missionWithStatus() {
      const mission = this.mission

      if (this.isComplete) return { ...mission, instructions: "Submitted" }

      return mission
    },
    statusText() {
      return !this.isUserTurn && "Waiting for your turn..."
    },
    isComplete() {
      const plays = this.$store.getters.missionPlaysArray
      const user = this.$store.state.auth.user

      return plays.some(play => play.userID === user.id)
    },
    isUserTurn() {
      const plays = this.$store.getters.missionPlaysArray
      const user = this.$store.state.auth.user

      const sortPattern = _.takeRight(
        String(this.missionStarted),
        this.scribes.length
      )

      const users = this.scribes.map((user, index) => ({
        ...user,
        order: sortPattern[index]
      }))

      const index = shuffle(users, this.seed).findIndex(
        ({ id }) => id === user.id
      )

      return index === plays.length
    }
  },
  watch: {
    price(value) {
      PriceIsRightService.submit(this.$store.state.auth.user, value)
      this.$store.dispatch("updateGameStatusAny", {
        endTime: this.mission.time * 1000 + Date.now()
      })
    }
  }
})
