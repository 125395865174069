var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MissionContainer",
    { attrs: { mission: _vm.mission } },
    [
      _vm.statusText
        ? _c("ResizableText", {
            staticStyle: { flex: "1" },
            attrs: { message: _vm.statusText },
          })
        : _c("TextInput", {
            attrs: {
              mode: _vm.mode,
              mission: _vm.missionWithStatus,
              mobile: "",
            },
            model: {
              value: _vm.price,
              callback: function ($$v) {
                _vm.price = $$v
              },
              expression: "price",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }